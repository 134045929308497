<script>
export default {
  computed: {
    signup() {
      return () => import(`@/components/Themes/${this.$cookies.get('theme') ?? 'default'}/Signup`);
    }
  },
  methods: {},
};
</script>
<template>
  <component :is="signup" v-if="signup"></component>
</template>